#sidebar-properties,
#sidebar-metadata {
  .field.widget {
    .buttons {
      display: flex;
      align-items: center;

      button {
        padding: 5px;
        border-radius: 3px;
        margin-right: 3px;
        aspect-ratio: 1/1;
        font-size: 1em;
        line-height: initial;
        &[data-hovered='true'] {
          box-shadow: inset 0 0 0 1px var(--border-color-pressed, #2996da);
        }
        &.active {
          box-shadow: inset 0 0 0 1px var(--border-color-pressed, #2996da);
        }

        svg.icon {
          margin: 0 !important;
          opacity: 0.9;
          vertical-align: top;
        }
      }
    }
  }
}

.field.theme-color-picker {
  .eight.wide.column {
    flex-direction: row;
    align-items: center;

    input {
      height: 32px;
      padding-left: 8px;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      line-height: 1;
    }

    .theme-color-picker-button .react-aria-ColorSwatch {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      cursor: pointer;
    }

    .theme-color-picker-reset {
      position: absolute;
      right: 15px;

      &[data-hovered='true'] {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
}

span.color-contrast-label {
  position: relative;
  display: block;
  padding-left: 20%;
  color: #ed6500;
  font-size: 13px;
  a {
    padding: 0.1rem 0.25rem;
    border: 1px solid var(--link-foreground-color);
    border-radius: 100%;
    margin: 0;
    margin-left: 4px;
    margin-left: 4px;
    font-size: 0.4rem;
    vertical-align: super;
  }
}

.react-aria-ColorSwatch {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3);
}

.color-swatch-widget {
  .buttons {
    flex: 1 1 100%;
    margin: 1rem 0 0.5rem;
    text-align: center;
  }

  button {
    position: relative;
    width: 32px;
    height: 32px;
    padding: 1rem;
    border: 2px solid #ccc;
    border-radius: 100%;
    margin-right: 0.25rem;

    &.active {
      border: 2px solid red;

      &:after {
        position: absolute;
        top: -0.25rem;
        right: -0.25rem;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 100%;
        background-color: red;
        content: '';
      }
    }
  }
}

.color-swatch-widget button {
  background-color: var(--theme-color);
}

.color-swatch-widget .button.grey,
.color-swatch-widget .button.grey.active,
.color-swatch-widget .ui.grey.button:hover {
  background-color: var(--theme-color);
}
.color-swatch-widget .button.active,
.color-swatch-widget .button.active:hover {
  background-color: var(--theme-color);
}

.buttons-widget {
  button {
    &[data-disabled] {
      border-color: var(--border-color-disabled);
      color: var(--text-color-disabled);
    }
  }
}

.object-list-widget {
  .field.inline {
    .add-item-button-wrapper {
      display: flex;
      justify-content: flex-end;
      padding: 0;

      button {
        @include button-style;
      }
    }

    .eight.wide.column:has(.add-item-button-wrapper) {
      padding-right: 0;
    }
  }

  .olw-item-wrapper {
    padding: 0 10px;
    border: 1px solid var(--quanta-smoke);
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .olw-item-title-bar {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    cursor: pointer;

    .drag.handle {
      display: flex;
      align-items: center;
    }
  }

  .olw-tools {
    display: flex;
    align-items: center;
    button {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}
