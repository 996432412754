.block.highlight {
  .teaser-item.top .highlight-description {
    .title h2 {
      @include highlight-title();
    }
    .description {
      a,
      p,
      span {
        @include introduction();
        --link-foreground-color: var(--theme-foreground-color);
      }
    }

    .button a {
      @include body-text-bold();
      text-decoration: none;
    }
  }
}
.block.highlight.has--descriptionColor--highlight-custom-color-2,
.block-editor-highlight.has--descriptionColor--highlight-custom-color-2 .block {
  --theme-foreground-color: #fff;
}
